import { AppBar, Typography, Toolbar, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors } from '../../utilities/styleConfig';
import strings from '../../utilities/strings';
import { VERSION, COPYRIGHT_YEAR } from '../../utilities/constants';

const useStyles = makeStyles((theme: Theme) => ({
	logo: {
		fontWeight: 400,
		fontSize: '1rem',
		color: colors.BLACK,
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			fontWeight: 400,
			fontSize: '0.4rem',
		},
	},
	footer: {
		backgroundColor: colors.LIGHT_WHITE,
		top: 'auto',
		bottom: 0,
		boxShadow: '0px -2px 4px rgba(124, 177, 209, 0.2)',
		zIndex: 1200,
	},
}));

interface Props {
	actionItems?: any;
	extraFooterElements?: any;
}

export default function Footer({ actionItems, extraFooterElements }: Props) {
	const { logo, footer } = useStyles();

	const Title = ({}) => (
		<Toolbar>
			<Typography variant="h6" component="h1" className={logo}>
				{strings.app_name} {strings.copyright} {COPYRIGHT_YEAR} {strings.version} {VERSION}{' '}
				{extraFooterElements}
			</Typography>
		</Toolbar>
	);

	const ActionItems = ({}) => {
		return (
			<Toolbar style={{ justifyContent: 'flex-end' }}>
				<Typography variant="h6" component="h1" className={logo}>
					{actionItems}
				</Typography>
			</Toolbar>
		);
	};

	return (
		<AppBar position="fixed" color="primary" className={footer}>
			<Grid container>
				<Grid item xs={3} sm={3} md={4} lg={4}>
					<Title />
				</Grid>
				<Grid item xs={9} sm={9} md={8} lg={8}>
					<ActionItems />
				</Grid>
			</Grid>
		</AppBar>
	);
}
