import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { colors, fontSizes, fontWeight } from '../../utilities/styleConfig';

interface Props {
	id?: string;
	type?: string;
	placeholder?: string;
	label?: string;
	onChange?: (e: any) => void;
	multiline?: any;
	rows?: number;
	onBlur?: (e: any) => void;
	value?: any;
	helperText?: string;
	error?: boolean;
	inputProps?: any;
	disabled?: boolean;
	InputProps?: any;
	style?: any;
	defaultValue?: any;
	marginTop?: any;
	marginBottom?: any;
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		// margin: '10% 10px',
	},
	paper: {
		padding: theme.spacing(2),
		// margin: 'auto',
		maxWidth: 650,
	},
	container: {},

	form: {
		width: '100%',
	},
	fieldLabels: {
		fontWeight: fontWeight._700,
		fontSize: fontSizes._22,
		color: colors.BLACK,
	},
	input: {
		backgroundColor: colors.LIGHT_WHITE,
	},
	focused: {
		backgroundColor: colors.LIGHT_WHITE,
	},
}));

const CustomTextField = (props: Props) => {
	const { id, type, placeholder, InputProps, defaultValue, marginBottom, marginTop } = props;
	const classes = useStyles();
	const InputPropsLocal = InputProps || {};
	const defaultValueProps = defaultValue != null ? {defaultValue: defaultValue} : {}
	// console.log("HIIIIII", defaultValue)
	return (
		<TextField
			// error={state.isError}
			fullWidth
			id={id}
			type={type}
			size="small"
			variant="filled"
			placeholder={placeholder}
			// margin="normal"
			{...defaultValueProps}
			sx={{minWidth: 80, marginTop: marginTop ? marginTop : '', marginBottom: marginBottom ? marginBottom : ''}}
			// defaultValue={0}
			InputProps={{
				classes: {
					root: classes.input,
					focused: classes.focused, // we can't forget to pass this in or &$focused in our input class won't work
				},
				// endAdornment: <InputAdornment position="end">%</InputAdornment>,
				...InputPropsLocal,
			}}
			// onChange={onChange}
			{...props}
		// onKeyPress={handleKeyPress}
		/>
	);
};

export default CustomTextField;
