import { Typography, Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors, fontSizes, fontWeight } from '../../utilities/styleConfig';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import Card from '@material-ui/core/Card';
import strings from '../../utilities/strings';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		width: 500,
		padding: '16px 24px',
	},
	logo: {
		fontWeight: fontWeight._400,
		fontSize: fontSizes._22,
	},
	actionContainer: {
		background: colors.LIGHT_WHITE,
		display: 'flex',
		justifyContent: 'flex-end',
		padding: 30,
		alignItems: 'center',
	},
	errorIcon: {
		color: 'red',
	},
	successIcon: {
		color: 'blue',
	},
	iconPosition: {
		position: 'absolute',
		right: 10,
		top: 30,
	},
	cardActions: {
		height: '6.25rem',
		background: colors.LIGHT_WHITE,
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
	},
	header: {
		fontWeight: fontWeight._700,
		fontSize: fontSizes._22,
		color: colors.BLACK,
		margin: theme.spacing(0),
		marginTop: '2%',
		marginBottom: '3%',
	},
	message: {
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.BLACK,
		marginBottom: '1%',
	},
}));

interface Props {
	error?: string;
	status?: boolean;
	actionItems?: any;
	cardView?: boolean;
	containerStyle?: any;
	iconStyle?: any;
}

export default function Footer({
	error,
	status,
	actionItems,
	cardView,
	containerStyle,
	iconStyle,
}: Props) {
	const classes = useStyles();
	// console.log(error, status, classes);
	return (
		<>
			{cardView ? (
				<Card
					style={{
						boxShadow: '0px 4px 12px 4px rgba(0, 0, 0, 0.16)',
						// height: ,
						width: '100%',
					}}
				>
					<div
						style={{ height: 10, width: '100%', backgroundColor: colors.PRIMARY_BTN }}
					></div>
					<div style={{ padding: 24, position: 'relative' }}>
						<Typography className={classes.header}>{strings.form_status}</Typography>

						<Typography className={classes.message}>{error}</Typography>

						<div className={classes.iconPosition}>
							{status ? (
								<CheckIcon style={{ color: 'blue' }} />
							) : (
								<WarningIcon style={{ color: 'red' }} />
							)}
						</div>
					</div>
					<div className={classes.cardActions}>{actionItems}</div>
				</Card>
			) : (
				<div style={containerStyle ? containerStyle : {}}>
					<Grid container className={classes.container}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<Typography>{error}</Typography>
						</Grid>
					</Grid>
					{actionItems && <div className={classes.actionContainer}>{actionItems}</div>}
					<div className={classes.iconPosition} style={iconStyle ? iconStyle : {}}>
						{status ? (
							<CheckIcon style={{ color: 'blue' }} />
						) : (
							<WarningIcon style={{ color: 'red' }} />
						)}
					</div>
				</div>
			)}
		</>
	);
}
