const images = {
	logo: require('../assets/images/logo.png'),
	logo_icon: require('../assets/images/logo_icon.png'),
	menu: require('../assets/images/Hamburger.png'),
	add: require('../assets/images/Add.png'),
	alerts: require('../assets/images/Alerts.png'),
	business_target: require('../assets/images/Business Targets.svg'),
	strategy_builder: require('../assets/images/Strategy Builder.svg'),
	chat: require('../assets/images/Chat.png'),
	profile: require('../assets/images/Profile.png'),
	check: require('../assets/images/Check.png'),
	dashboard: require('../assets/images/Dash.svg'),
	data_cube: require('../assets/images/Data Cube.svg'),
	data_insight: require('../assets/images/Data Insights.svg'),
	forcasting: require('../assets/images/Forecasting.svg'),
	settings: require('../assets/images/Settings.svg'),
	folder: require('../assets/images/Folder.png'),
	sampleQR: require('../assets/images/SampleQR.png'),
	stressTesting: require('../assets/images/Stress Testing.svg'),
	reporting: require('../assets/images/Reporting2.svg'),
	csv: require('../assets/images/csv.jpg').default,
	negative_forecast: require('../assets/images/NegativeForecast.png').default,
	positive_forecast: require('../assets/images/PositiveForecast.svg').default,
	create_strategy_modal: require('../assets/images/create_strategy_modal.png')
};

export default images;
