import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { colors, fontSizes } from '../../utilities/styleConfig';
import strings from '../../utilities/strings';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		backgroundColor: colors.PRIMARY_DARK,
	},
	logo: {
		fontWeight: 700,
		fontSize: fontSizes._22,
		color: '#FFFEFE',
		textAlign: 'left',
		[theme.breakpoints.down('md')]: {
			fontWeight: 700,
			fontSize: 22,
		},
	},
}));

export default function Header() {
	const { header, logo } = useStyles();

	const Title = ({}) => (
		<Toolbar>
			<Typography variant="h6" component="h1" className={logo}>
				{strings.app_name}
			</Typography>
		</Toolbar>
	);

	return (
		<AppBar className={header}>
			<Title />
		</AppBar>
	);
}
