const strings = {
	email: 'Email',
	password: 'Password',
	set_password: 'Set Password',
	cnfrm_pwd: 'Confirm Password',
	app_name: 'Prometics',
	confirm: 'Confirm',
	copyright: '©',
	dashboard: 'Dashboard',
	business_targets: 'Business Targets',
	data_cube: 'Data Cube',
	data_insights: 'Data Insights',
	strategy_builder: 'Strategy Builder',
	create_strategy: 'Create Strategy',
	forecasting: 'Forecasting',
	stress_testing: 'Stress Testing',
	reporting: 'Reporting',
	settings: 'Settings',
	add_new_user: 'Add New User',
	add_new_org: 'Add New Organization',
	suspend: 'Suspend',
	delete: 'Delete',
	frgt_pwd: 'Forgot Password?',
	register: 'New User? Register',
	sign_in: 'Sign In',
	email_id_or_username: 'Email',
	users: 'Users',
	logs: 'Logs',
	activate: 'Sign In',
	two_fa: 'Please enter two factor authentication from your registered device',
	google_qr_n_keys: 'Scan the QR Code',
	desc_1: 'Use an Authenticator App (Microsoft or Google) on your cell phone to scan the QR code. This will connect the Authenticator app with your account.',
	desc_2: 'After you scan the QR code, enter the provided 2 Factor-Authentication code below.',
	two_fa_code: '2-FA Code',
	no_creds: "Don't have credentials yet?",
	already_credentials: 'Already have your credentials?',
	contact_us: 'Contact Us',
	got_to_sign_in: 'Go to Sign-in',
	full_name: 'Full Name',
	first_name: 'First Name',
	last_name: 'Last Name',
	email_id: 'Email ID',
	p_no: 'Phone Number',
	name_of_org: 'Name of Organization',
	org: 'Organization',
	orgs: 'Organizations',
	message: 'Message',
	submit: 'Submit',
	send_request: 'Send Request',
	send_password_reset: 'Send Password Reset Request',
	sent_password: 'Password Reset Request',
	send_password_reset_detail: 'A request will be sent to admin for password reset.',
	send_device_reset: 'Send Device Reset Request',
	sent_device: 'Device Reset Request',
	send_device_reset_detail: 'A request will be sent to admin for device reset.',
	yes: 'Yes',
	no: 'No',
	export: 'Export',
	open: 'Open',
	send_reset_link: 'Send Reset Link',
	requests: 'Requests',
	cancel: 'Cancel',
	save: 'Save',
	profile: 'Profile',
	target_market: 'Target Market',
	market_share: '2-year market share target (%)',
	lending_channel: 'Lending Channel',
	risk_adjusted: '2-year lending risk adjusted yield target (%)',
	computed_table_header: 'Historical Industry Risk / Reward based on selection',
	select_product: 'Add assets and 2-year plan asset concentration',
	hints: 'Page guide',
	choose_strategy: 'Choose strategy to activate',
	selected_file: 'Selected File',
	remove: 'remove',
	preview: 'preview',
	fieldNames: 'Field Names',
	logout: 'Logout',
	roles: 'Roles',
	poc: 'Point Of Contact',
	org_domains: 'Organization Domains',
	subscription: 'Subscription',
	tech_query: 'Please write your queries here:',
	send: 'Send',
	back: 'Home',
	ok: 'Ok',
	is_admin: 'Is Admin',
	add_asset: 'Add New Asset',
	asset_name: 'Asset Name',
	concentration: 'Concentration (%)',
	tech_support: 'Tech Support',
	close: 'Close',
	form_status: 'Form Submission Status',
	pending: 'Pending',
	completed: 'Completed',
	all: 'All',
	status: 'Status',
	device_change_request: 'Change registered device',
	filter_by_org: 'Filter by Organisation',
	version: 'Version',
	no_unread_alerts: 'No Unread Alerts',
	unallocated_conc: (unallocated_conc: string) =>
		`Unallocated concentration: ${unallocated_conc}% / 100%`,
	upload_new_file: '1. Upload New File',
	indicators: 'Indicators',
	swap_set_analysis: 'Swap Set Analysis',
	select_from_Uploaded_files: '2. Select file used for analytics',
	instructions: 'Instructions',
	merge: 'Merge',
	import_data_instructions: [
		`Download the Prometics Data Template and Data Dictionary`,
		`Create your data file on your end in CSV or XML format following the template format. Leave fields blank when the data is not available. Add any other data fields that you would like to analyze at the end of the file`,
		`Upload the data file to the Prometics platform following the instructions below. You can also merge files if you are adding additional history`,
		`Select your file. Use the Revert button to come back to the previous file selection`,
	],
	type: 'Type',
	asset: 'Asset',
	target: 'Target',
	add_new_strategy: 'Add New Strategy',
	load: 'Load',
	deactivate: 'Deactivate Now',
	activate_strategy: 'Activate Now',
	validate: 'Validate',
	share: 'Share',
	select: 'Select',
	info_modal: (field_name: string) => `Preview of the first 10 rows of the ${field_name}`,
	uploading: 'Uploading...',
	information: 'Information',
	earliest: 'earliest',
	latest: 'latest',
	warning_targetRate: 'Warning, less than 24 months of application volume available in file.',
	warning_targetVolume: 'Warning, less than 12 months of application volume available in file.',
	rangeTV: 'Max months on book',
	rangeTR: 'Min-Max months on book',
	months: ' months',
	okay: 'Okay',
	please_wait: 'Please wait...',
	annualised_charged: 'Annualized Net Charge-Off Rates',
	term: 'Term',
	premium_by_term: 'Premium By Term',
	pricing_by_term: 'Pricing By Term',
	start: 'Start',
	end: 'End',
	portfolio_window: 'Change Performance Months on Book',
	portfolio_warning: 'Warning, less than 24 months of portfolio performance available in file.',
	force_variable: 'Force Variable',
	update: 'Update',
	data_field: 'Data Field',
	min: 'Min',
	max: 'Max',
	add: 'Add',
	select_operator: 'Select Operator',
	enter_cutoff_value: 'Enter cut-off value',
	back_to_login: 'Back to Login',
	view_keyDrivers: 'View Key Drivers',
	savePlan: 'Save Plan',
	please_select_file: 'Please select a file!',
	strategy_filters: 'Strategy Filters',
	strategy_builder_instructions: [
		`Create or edit a strategy`,
		`Select and activate strategy used in forecasting`
	],
	upload_file_toolTip: 'Copy your data into template. Add any supplemental data for analysis. Save into CSV format. Upload file',
	more_info: 'more info on file format',
	add_column: 'Add Column',
	clear: 'Clear',
	select_metrics: 'Select Metrics',
	add_new_scenario: 'Add New Scenario',
	scenario_name: 'Scenario Name',
	dti_cap: 'DTI Cap',
	copy: 'Copy',
	paste: 'Paste',
	cap_name: 'Cap Name',
	unit: 'Unit',
	add_additional_cap: 'Add Additional Cap',
	pricing_credit: 'Pricing by credit_score1',
	pricing_income: 'Pricing by annual_income',
	add_new_forecast: 'Add New Forecast',
	forecast_name: 'Forecast Name',
	view_graphs: 'View Graphs and Tables',
	amount_credit: 'amount_financed by credit_score1',
	amount_income: 'amount_financed by annual_income',
	forecast_report: 'Model Document',
	model_document_text: 'Generate SR 11-7 Model Document',
};

export default strings;