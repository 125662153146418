import { gql } from '@apollo/client';

export const LOGIN = gql`
	mutation loginUser($email: String!, $password: String!) {
		loginUser(payload: {email: $email, password: $password}) {
			token
            device
            status {
                status
                error
            }
            confirmed
		}
	}
`;


export const MFA_LOGIN = gql`
	mutation multiFactorLoginUser($token: String!) {
		multiFactorLoginUser(payload: {token: $token}) {
            token
            status {
                status
                error
            }
            me {
                firstName
                lastName
                email
            
            }
		}
	}
`;

export const RESET_PASSWORD_REQUEST = gql`
	mutation resetPasswordRequest($email: String!) {
		resetPasswordRequest(payload: {email: $email}) {
           status
           error
		}
	}
`;


export const RESET_DEVICE_REQUEST = gql`
	mutation resetDeviceRequest($email: String!) {
		resetDeviceRequest(payload: {email: $email}) {
           status
           error
		}
	}
`;

export const SEND_CONTACT_EMAIL = gql`
	mutation sendContactEmail($subject: String!, $body: String!, $to: String!) {
		sendContactEmail(payload: {subject: $subject, body: $body, to: $to}) {
           status
           error
		}
	}
`;

export const APPROVE_REQUEST = gql`
	mutation approveRequest($ids: [String!]) {
		approveRequest(ids: $ids) {
           status
           error
		}
	}
`;


export const REJECT_REQUEST = gql`
	mutation rejectRequest($ids: [String!]) {
		rejectRequest(ids: $ids) {
           status
           error
		}
	}
`;


export const GET_USERS = gql`

    query users($filter: UserFilterInput, $limit: Int, $page: Int, $sortOrder: [sortingOrder]) {
        users(filter: $filter, limit: $limit, page: $page, sorted: $sortOrder ) {
            
            count
            users {
                username
                id
                firstName
                lastName
                customer {
                    name
                    id
                }
                status
                phone
                roles
                
            }
        }

        customers{
            customers{
                id
                name
            }
        }
    }



`;


export const GET_USERS_CUSTOMER = gql`

    query users($customer: String!) {
        users(filter: { customer: $customer }) {
            
            count
            users {
                username
                id
                firstName
                lastName
               
            }
        }
    }



`;

export const GET_AUDIT_LOGS = gql`

    query logs($userId: String!, $filter: LogFilterInput, $limit: Int, $page: Int) {
        logs(id: $userId, filter: $filter, limit: $limit, page: $page) {
            count
            logs {
                
                historyType
                historyChangeReason
                historyDate
                changedBy
               
            }
        }
    }



`;

export const GET_REQUESTS = gql`

    query requests($filter: RequestFilterInput, $limit: Int, $page: Int) {
        requests(filter: $filter, limit: $limit, page: $page) {
            
            count
            requests {
                id
                name
                description
                user {
                    username
                    customer {
                        name
                    }
                    phone
                }
                requestType
                status
                createdDate
            }
        }
    }



`;


export const GET_NOTIFICATIONS = gql`

    query notifications{
        notifications {
            verb
            unread
            id
            timestamp
            description
            level
        }

        me {
            firstName
            lastName
            username
            customer {
                name
            }
            phone
            id
        }

        getSelectedFile{
            id
            fileName
          }

    }



`;

export const SET_PASSWORD = gql`
	mutation setPassword($id: String!, $token: String!, $password: String!) {
		setPassword(payload: {token: $token, id: $id, password: $password}) {
           status
           error
		}
	}
`;


export const ADD_USER = gql`
	mutation createUser($firstName: String!, $lastName: String!, $customer: String!, $email: String!, $roles: [String!]!, $phone: String!) {
		createUser(payload: { firstName: $firstName, lastName: $lastName, customerId: $customer, email: $email, roles: $roles, phone: $phone}) {
           status
           error
		}
	}
`;

export const UPDATE_USER = gql`
	mutation updateUser($id: String!, $firstName: String, $lastName: String, $roles: [String], $phone: String) {
		updateUser(payload: { id: $id, firstName: $firstName, lastName: $lastName,roles: $roles, phone: $phone}) {
           status
           error
		}
	}
`;

export const DELETE_USER = gql`
	mutation deleteUser($ids: [String!]) {
		deleteUser(ids: $ids) {
           status
           error
		}
	}
`;

export const ARCHIVE_USER = gql`
	mutation archiveUser($ids: [String!]) {
		archiveUser(ids: $ids) {
           status
           error
		}
	}
`;

export const UNARCHIVE_USER = gql`
	mutation unarchiveUser($ids: [String!]) {
		unarchiveUser(ids: $ids) {
           status
           error
		}
	}
`;

export const MARK_AS_READ = gql`
	mutation markAsRead{
		markAsRead{
           status
           error
		}
	}
`;

export const MARK_AS_DONE = gql`
	mutation markAsDone($ids: [Int]) {
		markAsDone(ids: $ids) {
           status
           error
		}
	}
`;

export const GET_ROLE_LIST = gql`
    query getRolesList{
        rolesList{
            id
            name
        }
    }
`;

export const EXPORT_AUDIT_LOGS = gql`
  query exportAuditLogs($userId: String!, $filter: LogFilterInput) {
      exportAuditLogs(id: $userId, filter: $filter) {
          auditLogs
      }
  }
`;