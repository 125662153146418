const colors = {
	PRIMARY_DARK: '#0F212E',
	WHITE: '#fff',
	BLACK: '#000000',
	LIGHT_WHITE: '#F2F7FA',
	PRIMARY_BTN: '#3E62AC',
	PRIMARY_TEXT_COLOR: '#3E62AC',
	GREEN_BTN: '#12B052',
	RED_BTN: '#EE4444',
	GRAY_BTN: '#7C7B7B'
};

const fontSizes = {
	_10: 'calc(6px + 0.3125vw)',
	_12: 'calc(6px + 0.3125vw)',
	_14: `calc(6px + 0.3125vw)`,
	_16: `calc(6px + 0.3125vw)`, //10
	_18: `calc(8px + 0.3125vw)`, //12
	_20: `calc(10px + 0.3125vw)`, //14
	_22: `calc(10px + 0.46875vw)`, //16
};

const fontWeight = {
	_400: 400,
	_600: 600,
	_700: 700,
};

const lineHeight = {
	_10: '0.78125vw',
	_12: '0.9375vw',
	_14: '1.09375vw',
	_16: '1.25vw',
	_18: '1.40625vw',
	_20: '1.5625vw',
	_22: '1.71875vw',
};

export { colors, fontSizes, fontWeight, lineHeight };
