import { Redirect, Route, RouteProps } from 'react-router';
import { clearSession, isJwtTokenValid } from '../utilities/helpers';
import { useLocation } from 'react-router-dom';
import { lazy } from 'react';

const Forbidden = lazy(() => import('../components/screens/Login/Forbidden'));
export type ProtectedRouteProps = {
    authenticationPath: string;
    verificationPath: string;
    roles?: string[];
} & RouteProps;

export default function ProtectedRoute({ roles, authenticationPath, verificationPath, ...routeProps }: ProtectedRouteProps) {

    const location = useLocation();
    const { hasExpired, isVerified, roles: tokenRoles } = isJwtTokenValid();
    if (!hasExpired && isVerified) {
        if (roles && roles.length > 0) {
            const roleIntersection = roles.filter(value => tokenRoles.includes(value));
            if (roleIntersection.length === 0) {
                const { component, ...rest } = routeProps;
                return <Route component={Forbidden} {...rest} />
            }

        }

        return <Route {...routeProps} />;

    } else if (!hasExpired && !isVerified) {

        if (location.pathname === verificationPath) {
            return <Route {...routeProps} />;
        }
        return <Redirect to={{ pathname: verificationPath }} />;
    }
    else {
        clearSession();
        return <Redirect to={{ pathname: authenticationPath }} />;
    }
};