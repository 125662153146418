import { createTheme, responsiveFontSizes,makeStyles } from '@material-ui/core/styles';
import { colors, fontWeight, fontSizes } from './styleConfig';

const theme = createTheme({
	palette: {
		primary: {
			main: '#61dafb',
		},
		secondary: {
			main: '#282c34',
		},
	},
	typography: {
		fontFamily: [
			'Roboto', 'sans-serif'
		].join(','),
		h3:{
			fontSize:22,
			fontWeight:700,
			fontFamily: [
				'Montserrat', 'sans-serif'
			].join(','),
		}
	},
	
});

const commonStyles = makeStyles(() => ({
	btn: {
		backgroundColor: colors.PRIMARY_BTN,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.WHITE,
		textTransform: 'none',
		height: 42,
		boxShadow: 'none',
		marginLeft: 10,
		borderRadius: 0,
	},
	greenBtn: {
		backgroundColor: colors.GREEN_BTN,
	},
	redBtn: {
		backgroundColor: colors.RED_BTN,
	},
	greenFooterBtn: {
		backgroundColor: colors.GREEN_BTN,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.WHITE,
		textTransform: 'none',
		height: 42,
		boxShadow: 'none',
		marginLeft: 10,
		borderRadius: 0,
	},
	redFooterBtn: {
		backgroundColor: colors.RED_BTN,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.WHITE,
		textTransform: 'none',
		height: 42,
		boxShadow: 'none',
		marginLeft: 10,
		borderRadius: 0,
	},
	grayFooterBtn: {
		backgroundColor: colors.GRAY_BTN,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.WHITE,
		textTransform: 'none',
		height: 42,
		boxShadow: 'none',
		marginLeft: 10,
		borderRadius: 0,
	},
	header: {
		fontSize: fontSizes._22,
		fontWeight: fontWeight._700,
	},
	clickableTableHeader: {
		  color:'#3E62AC',
		  textDecoration:'underline'
	},
	flex_center:{
		display:'flex',
		alignItems:'center'
	}
}));

export default responsiveFontSizes(theme);

export {
	commonStyles
}