import jwt_decode from 'jwt-decode';

export const isJwtTokenValid = () => {
	const token = localStorage.getItem('token') || '';
	let hasExpired = true;
	let isVerified = false;
	let roles = [];
	let lastLogin = null;

	if (Boolean(token)) {
		const decoded: any = jwt_decode(token);
		if (Date.now() < decoded.exp * 1000) {
			hasExpired = false;
			if (Boolean(decoded.verified)) {
				isVerified = true;
			}
			if (Boolean(decoded.roles)) {
				roles = decoded.roles;
			}

			if (Boolean(decoded.lastLogin)) {
				lastLogin = decoded.lastLogin;
			}
		}
	}

	return { hasExpired, isVerified, roles, lastLogin };
};

export const clearSession = () => {
	['device', 'token'].forEach((k) => localStorage.removeItem(k));
};

export const isTokenPresent = () => {
	return Boolean(localStorage.getItem('token'));
};

export const capitalizedCamelCase = (snake: string) => {
	return snake
		? snake
			.split('_')
			.map((s: string) => s.charAt(0).toUpperCase() + s.slice(1))
			.join(' ')
		: '';
};

export const capitalize = function (word: string) {
	const lower = word.toLowerCase();
	return word.charAt(0).toUpperCase() + lower.slice(1);
}

export const getDefaultRoute = () => {
	const { hasExpired, isVerified, roles } = isJwtTokenValid();
	const adminRoles = ['sub_admin', 'super_admin'];
	const analyzerRole = ['analyzer']
	if (!hasExpired && isVerified) {
		const roleIntersection = roles.filter((value: any) => adminRoles.includes(value));
		if (roleIntersection.length > 0) {
			return '/settings';
		}
		const roleIntersection1 = roles.filter((value: any) => analyzerRole.includes(value));
		if(roleIntersection1.length > 0){
			return '/datacube';
		}
		return '/businesstargets';
	}
	return '/';
};

export const downloadCSV = (csvContent: string, filename: string) => {
	var hiddenElement = document.createElement('a');
	hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvContent);
	hiddenElement.target = '_blank';
	hiddenElement.download = filename;
	hiddenElement.click();
};

export const downloadPDF = (pdfContent: any, fileName: string) => {
	const linkSource = `data:application/pdf;base64,${pdfContent}`;
	const downloadLink = document.createElement("a");
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
};

export const downloadWord = (wordContent: any, fileName: string) => {
	const linkSource = `data:application/msword;base64,${wordContent}`;
	const downloadLink = document.createElement("a");
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
};

export const downloadHTML = (htmlContent: any, fileName: string) => {
	const linkSource = `data:application/html;base64,${htmlContent}`;
	const downloadLink = document.createElement("a");
	downloadLink.href = linkSource;
	downloadLink.download = fileName;
	downloadLink.click();
};

export const deleteKeyfromObject = (e: any, keyString: string) => {
	return Object.keys(e)
		.filter((key: string) => key !== keyString)
		.reduce((obj, key) => {
			obj[key] = e[key];
			return obj;
		}, {});
};
