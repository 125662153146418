import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import { colors, fontSizes, fontWeight } from '../../../utilities/styleConfig';
import images from '../../../utilities/images';
import strings from '../../../utilities/strings';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import CustomTextField from '../../common/CustomTextField';
import { withFormik, FormikProps } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '@material-ui/lab/LoadingButton';
import { useMutation } from '@apollo/client';
import { SET_PASSWORD } from '../../../graphql/users';
import queryString from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import PrometricsAlert from '../../common/PrometicsAlert';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		margin: '10% 10px',
	},
	paper: {
		padding: theme.spacing(2),
		margin: 'auto',
		maxWidth: 650,
	},
	imageDiv: {
		display: 'flex',
		justifyContent: 'center',
	},
	img: {
		width: 300,
		objectFit: 'contain',
		marginBottom: 30,
	},
	container: {},
	loginBtn: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
		flexGrow: 1,
		backgroundColor: colors.PRIMARY_BTN,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.WHITE,
		textTransform: 'none',
		height: 42,
		boxShadow: 'none',
		'&:hover': {
			backgroundColor: '#6391F1',
		},
	},
	header: {
		textAlign: 'center',
		background: '#212121',
		color: '#fff',
	},
	form: {
		width: '100%',
	},
	cardActions: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	frgtPwd: {
		cursor: 'pointer',
		color: colors.BLACK,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._18,
		textAlign: 'center',
		marginTop: 20,
		marginBottom: 50,
		textDecoration: 'underline',
	},
	fieldLabels: {
		fontWeight: fontWeight._700,
		fontSize: fontSizes._22,
		color: colors.BLACK,
	},
	input: {
		backgroundColor: colors.LIGHT_WHITE,
	},
	focused: {
		backgroundColor: colors.LIGHT_WHITE,
	},
	contactUsLabel: {
		fontWeight: fontWeight._400,
		fontSize: fontSizes._18,
		color: colors.BLACK,
	},
	contactUsBtn: {
		marginTop: theme.spacing(2),
		backgroundColor: colors.WHITE,
		fontWeight: fontWeight._400,
		fontSize: fontSizes._20,
		color: colors.BLACK,
		textTransform: 'none',
		width: 200,
		boxShadow: 'none',
		border: '1px solid #000000',
		height: 42,
	},
	card: {
		boxShadow: '0px 4px 12px 4px rgba(0, 0, 0, 0.16)',
	},
	cardContent: {
		padding: '5% 15%',
	},
	linkColor: {
		color: colors.BLACK,
	},
	contactUs: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		flexDirection: 'column',
		marginTop: 32,
		alignItems: 'center',
	},
	loginError: {
		// height: 30,
		// width: '100%',
		color: '#721c24',
		backgroundColor: '#f8d7da',
		boxShadow: '0 1px 0 0 #f5c6cb',
		borderRadius: 5,
		fontSize: '.875rem',
		padding: '1rem 3rem 1rem 1rem',
		marginBottom: '1rem',
	},
	loginSuccess: {
		// height: 30,
		// width: '100%',
		color: '#115D14',
		backgroundColor: '#B3EFB5',
		boxShadow: '0 1px 0 0 #f5c6cb',
		borderRadius: 5,
		fontSize: '.875rem',
		padding: '1rem 3rem 1rem 1rem',
		marginBottom: '1rem',
	},
}));

interface Props {
	isLoading?: boolean;
	error?: string;
	statusState: boolean;
}

interface FormValues {
	password: string;
	confirm_password: string;
}

const SetPassword = (props: Props & FormikProps<FormValues>) => {
	const classes = useStyles();
	const { values, errors, touched, handleChange, handleBlur, handleSubmit, isLoading, error, statusState } =
		props;

	const history = useHistory();

	console.log('>>>>>>>error', error);
	console.log('>>>>', statusState)
	
	return (
		<>
			<Header />
			<div className={classes.root}>
				<div className={classes.paper}>
					<form className={classes.form} onSubmit={handleSubmit}>
						<Card className={classes.card}>
							<CardContent className={classes.cardContent}>
								<div className={classes.imageDiv}>
									<img
										alt="logo"
										src={images.logo.default}
										className={classes.img}
									/>
								</div>
								{error ? (
									<PrometricsAlert
										error={error}
										status={Boolean(statusState)}
										containerStyle={{ position: 'relative' }}
										iconStyle={{ top: -10, right: '45%' }}
									/>
								) : (
									<div>
										<Typography className={classes.fieldLabels}>
											{strings.set_password}
										</Typography>
										<CustomTextField
											id="password"
											type="password"
											placeholder={strings.set_password}
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.password}
											helperText={touched.password ? errors.password : ''}
											error={touched.password && Boolean(errors.password)}
										/>
										<Typography className={classes.fieldLabels}>
											{strings.cnfrm_pwd}
										</Typography>
										<CustomTextField
											id="confirm_password"
											type="password"
											placeholder={strings.cnfrm_pwd}
											onChange={handleChange}
											onBlur={handleBlur}
											value={values.confirm_password}
											helperText={
												touched.confirm_password
													? errors.confirm_password
													: ''
											}
											error={
												touched.confirm_password &&
												Boolean(errors.confirm_password)
											}
										/>
									</div>
								)}
							</CardContent>
							<div className={classes.cardActions}>
								{error ? (
									<LoadingButton
										variant="contained"
										size="large"
										onClick={() => history.replace('/')}
										loading={isLoading}
										className={classes.loginBtn}
									>
										{strings.back}
									</LoadingButton>
								) : (
									<LoadingButton
										variant="contained"
										size="large"
										type="submit"
										loading={isLoading}
										className={classes.loginBtn}
										disabled={
											!values.password ||
											!values.confirm_password ||
											values.password !== values.confirm_password
										}
									>
										{strings.confirm}
									</LoadingButton>
								)}
							</div>
						</Card>
					</form>
				</div>
			</div>
			<Footer />
		</>
	);
};

const SetPasswordWrapper = (props: Props) => {
	const [statusState, setStatus] = React.useState(false);
	const [error, setError] = React.useState('');
	const [qs, setQs] = React.useState({});
	const location = useLocation();

	useEffect(() => {
		const parsedQuery = queryString.parse(location.search);
		if (!parsedQuery.token || !parsedQuery.id) {
			setError('The link is invalid.');
		} else {
			setQs(parsedQuery);
		}
	}, []);

	const [setPassword, { loading }] = useMutation(SET_PASSWORD, {
		onCompleted: (data) => {
			const { setPassword } = data || {};
			const { error, status } = setPassword;
			// console.log(status, statusState)
			setStatus(status)
			setError(error);
			
		},
	});

	const SetPasswordFormWrapper = withFormik<Props, FormValues>({
		mapPropsToValues: ({}) => ({
			password: '',
			confirm_password: '',
		}),

		validationSchema: Yup.object().shape({
			password: Yup.string()
				.required('Enter your password')
				.matches(
					/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
					'Passwords must be at least 8 letters long and must have a symbol (allowed symbols are ! * @ # & % $), upper and lower case letters and a number.'
				),
			confirm_password: Yup.string()
				.required('Confirm your password')
				.oneOf([Yup.ref('password')], 'Password does not match'),
		}),

		handleSubmit(values: FormValues) {
			const { password } = values;
			setPassword({
				variables: {
					password,
					...qs,
				},
			});
		},
	})(SetPassword);

	return <SetPasswordFormWrapper {...props} error={error} statusState={statusState} isLoading={loading} />;
};

export default SetPasswordWrapper;
