import { lazy, Suspense } from 'react';
import { LinearProgress } from '@material-ui/core';
import { Route, Switch } from 'react-router';
import { HashRouter } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import SetPassword from './screens/SetPassword';
const Login = lazy(() => import('./screens/Login'));
const TwoFA = lazy(() => import('./screens/TwoFA'));
const Settings = lazy(() => import('./screens/Settings'));
// const SetPassword = lazy(() => import('./screens/SetPassword'));
// const SampleTable = lazy(() => import('./screens/SampleTable'));
// const HighCharts = lazy(() => import('./screens/DemoScreens/HighCharts'));
// const Chartjs = lazy(() => import('./screens/DemoScreens/Chartjs'));
// const Plotly = lazy(() => import('./screens/DemoScreens/Plotly'));
// const DataGrid = lazy(() => import('./screens/DemoScreens/DataGrid'));
const BusinessTargets = lazy(() => import('./screens/BusinessTargets'));
const DataCube = lazy(() => import('./screens/Datacube'));
const StrategyBuilder = lazy(() => import('./screens/StrategyBuilder'));
const Forecasting = lazy(() => import('./screens/Forecasting'));
const Pricing = lazy(() => import('./screens/StrategyBuilder/Pricing'));
const EDAReport = lazy(() => import('./screens/Datacube/EDA'))

const AppRoutes = () => {
	return (
		<HashRouter>
			<Switch>
				<Route exact path="/setpassword" component={SetPassword} />
				<Suspense fallback={<LinearProgress />}>
					<Route exact path="/" component={Login} />
					<ProtectedRoute
						exact
						path="/mfa"
						component={TwoFA}
						authenticationPath="/"
						verificationPath="/mfa"
					/>
					<ProtectedRoute
						exact
						path="/settings"
						component={Settings}
						authenticationPath="/"
						verificationPath="/mfa"
						roles={['sub_admin', 'super_admin']}
					/>
					<ProtectedRoute
						exact
						path="/businesstargets"
						component={BusinessTargets}
						authenticationPath="/"
						verificationPath="/mfa"
					/>
					<ProtectedRoute
						exact
						path="/datacube"
						component={DataCube}
						authenticationPath="/"
						verificationPath="/mfa"
					/>
					{/* <Route exact path="/sampleTable" component={SampleTable} />
					<Route exact path="/highcharts" component={HighCharts} />
					<Route exact path="/chartjs" component={Chartjs} />
					<Route exact path="/plotly" component={Plotly} />
					<Route exact path="/datagrid" component={DataGrid} /> */}
					<Route exact path="/strategybuilder" component={StrategyBuilder} />
					<Route exact path="/pricing" component={Pricing} />
					<ProtectedRoute
						exact
						path="/forecasting"
						component={Forecasting}
						authenticationPath="/"
						verificationPath="/mfa"
					/>
					<Route exact path="/eda-report" component={EDAReport} />
				</Suspense>
			</Switch>
		</HashRouter>
	);
};

export default AppRoutes;
